

/*Styling ordered from top to bottom of web page.*/
.navTitle {
  align-items: center;
  text-align: center;
  display: flex;
  color: #000000;
  border: 0;
  border-radius: 0px;
  box-shadow: rgba(140, 6, 265, 0.2);
  padding: 3px;
  font-size: 15px;
  justify-content: space-evenly;
  width: auto;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #b7b7b7;
}

globalMedia {
  align-items: center;
  text-align: center;
}
/* Global Media query for smaller screens */
@media screen and (max-width: 300px) {
  .globalMedia {
    flex-direction: column;
    /* Change to column layout for smaller screens */
    align-items: center;
    
  }
}

/*Styling for nav navigation options*/
.navOptionsTop {
  align-items: center;
  text-align: center;
  display: flex;
  background-color: #b7b7b7;
  color: #000000;
  border: 0;
  border-radius: 0px;
  box-shadow: rgba(140, 6, 265, 0.2);
  padding: 3px;
  font-size: 15px;
  justify-content: space-evenly;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  filter: brightness(110%);
}

.navOptionsTop:active,
.navOptionsTop:hover {
  background-color: #a1a7b0 50%;
  color: #a1a7b0 50%;
}

/*Styling for nav navigation options*/
.navOptionsTop-but {
  align-items: center;
  text-align: center;
  display: flex;
  background-color: #b7b7b7;
  color: #000000;
  border: 0;
  border-radius: 0px;
  box-shadow: rgba(140, 6, 265, 0.2);
  padding: 3px;
  font-size: 15px;
  justify-content: space-evenly;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: pointer;
  filter: brightness(120%);
}

.navOptionsTop-but:active,
.navOptionsTop-but:hover {
  background-color: #727272;
  color: #616161;
}

.siteMap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3px;
  cursor: pointer;
  /* Show pointer cursor on hover */
  font-size: 20px;
  transition: color 0.3s, box-shadow 0.3s;
  /* Add a transition for smooth animation */
  box-shadow: 0 2px 4px rgba(2, 179, 191, 0.1);
}

.siteMap:active,
.siteMap:hover {
  color: #616161;
}

.SearchContainer {
  margin: 20px auto; /* Center the container horizontally */
  max-width: 85%;
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap to the next line */
  align-items: center;
  border: 1px solid rgb(128, 128, 128); /* Border around the container */
  border-radius: 5px; /* Border radius */
  overflow: hidden; /* Clear floats */
}

.SearchContainer > div {
  flex: 1;
}

@media screen and (max-width: 360px) { 
   .SearchContainer{
    width: 100%;
   }
}

/* The apps search card theme */
.cardSearch {
  margin: 20px;
  display: flex;
  text-align: left;
  border-radius: 0px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-color: #d4def1;
  filter: brightness(120%);
}

/* More Information styles */
.moreInfo {
  margin: 20px;
  /* Adjust margins as needed */
  display: flexbox;
  font-size: 20px;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  line-height: 1;
  transition: color 0.3s, box-shadow 0.3s;
  /* Add a transition for smooth animation */
  box-shadow: 0 2px 4px rgba(2, 179, 191, 0.1);
}

/* Styling items under more information*/
.infoList {
  color: #135897;
  padding: 5%;
  /* Add a transition for smooth animation */
  box-shadow: 0 2px 4px rgba(80, 87, 88, 0.1);
}

.infoList:hover {
  cursor: pointer;
  /* Show pointer cursor on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Add a shadow on hover */
}

.weatherAlerts {
  margin: 20px auto; /* Center the container horizontally */
  max-width: 85%;
  border: 1px solid rgb(128, 128, 128); /* Border around the container */
  border-radius: 5px; /* Border radius */
  overflow: hidden; /* Clear floats */
  font-size: 16px;
  /* line-height: 1;   line spacing after each line*/
  color: red;
  align-items: center;
  text-align: center;
  background-color: #b7b7b7;
  border: 0;
  border-radius: 5px;
  box-shadow: rgba(140, 6, 265, 0.2);
  padding: 3px;
  font-size: 15px;
  justify-content: space-evenly;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  filter: brightness(110%);
}

.warnZone {
  margin: 20px auto; /* Center the container horizontally */
  max-width: 85%;
  font-size: 16px;
  line-height: 1;
  /* line spacing after each line*/
  color: rgb(255, 0, 0);
  align-items: center;
  text-align: center;
}

.alertText {
  margin: 20px auto; /* Center the container horizontally */
  max-width: 85%;
  font-size: 16px;
  line-height: 1;
  align-items: center;
  text-align: center;
  /* line spacing after each line*/
  color: rgb(255, 0, 0);
  /* Add a transition for smooth animation */
  cursor: pointer;
  /* Show pointer cursor on hover */
}

.alertModal {
  margin: 10px;
  font-size: 16px;
  line-height: 1;
  /* line spacing after each line*/
  color: rgb(0, 0, 0);
  margin: 20px auto; /* Center the container horizontally */
  max-width: 85%;
}


/* current city heading styles */
.currentWeatherContainer {
  margin: 20px auto; /* Center the container horizontally */
  max-width: 85%;
  border: 1px solid rgb(128, 128, 128); /* Border around the container */
  border-radius: 5px; /* Border radius */
  overflow: hidden; /* Clear floats */
}

.current-heading {
  font-size: 12px;
  line-height: 1.5; /* line spacing after each line */
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  filter: brightness(110%);
  text-align: left;
  background-color: #b7b7b7;
  padding: 10px; /* Add padding to create space */
  gap: 10px;
}

.current-heading > div {
  flex: 1;
  margin-right: 10px;
}

@media screen and (max-idth:360px) {
.current-heading  {
  width: 100%;
 }
}

/* current city name styling */
.current-City h2 {
  font-size: 16px;
  color: #00284d;
  font-weight: bold;
  margin: 0; /* Reset margin to ensure consistent spacing */
}

/* Lattitue, Longitute, and elevation spacing*/
.lat-lon-elev {
  font-size: 11px;
  display: flex;
  gap: 10px;
  margin: 0; /* Reset margin to ensure consistent spacing */
}

/* Current Weather image, description, F and C temps, and weather items onright side styles*/
.currentData {
  display: flex;
  justify-content: flex-start;
  margin: 10px;
}

/* Media query for smaller screens */
@media screen and (max-width: 360px) {
  .currentData {
    flex-direction: column;
    /* Change to column layout for smaller screens */
    align-items: center;
  }
}

.temp-container {
  font-size: 11px;
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap to the next line */
  align-items: center;
  width: 50%;
  margin: 10px;
}

.temp-container > div {
  flex: left; /* Make each child div flexible */
 line-height: .8;
}

@media screen and (max-width: 768px) {
  .temp-container {
    width: auto; /* Adjust width to fit smaller screens */
  }
}


.weatherIconSpace {
  font-size: 11px;
  margin: 10px;
}

.text-descriptionContainer {
  margin: 10px;
}

.temp-description-font {
  font-size: 30px;
  font-weight: bold;
}

.tempDescSpacing {
  margin-top: 0;
  margin-bottom: 0;
}

.locationDatafahrenheit,
.locationDataCelcius {
  
  margin-left: 5px;
  margin-right: 50px;
}

/*Styling for the current data thats at the center.*/
.center-current-data {
  display: flex;
  font-size: 11px;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.center-data-item {
  margin-bottom: 1px;
}
/*Add text style and color to headers for each section*/
.Extended-Forecast-container {
  margin: 20px;
  border: 1px solid rgb(128, 128, 128); /* Border around the container */
  border-radius: 5px; /* Border radius */
  overflow: hidden; /* Clear floats */
}

.Extended-Forecast-header {
  display: flex;
  flex-direction: column;
  line-height: 0.3; /* line spacing after each line*/
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  filter: brightness(110%);
  text-align: left;
  background-color: #b7b7b7;
 }
/*Style for extended forecast.*/
.ext-fore {
  margin: 10px;
  font-size: 12px;
  font-weight: bold;
}
/*Styles for the extended forecast City name*/
.ext-city-name {
  font-size: 18px;
  color: #031d36;
  font-weight: bold;
}

/* The apps search button color theme */
.btn-color-theme,
.btn-color-theme:hover,
.btn-color-theme:active {
  background-color: #b7b7b7;
  border-color: #d4def1;
}

/* The apps search button color theme hover highlight text*/
.btn-color-theme:hover,
.btn-color-theme:active {
  background-color: #ffffff;
  color: #acaeae;
}

/*Extended Forecast fitting all data within the container.*/
.ExFo-container {
  margin: 10px;
  padding: 10px;
  overflow: auto;
  width: auto;
}

/* Container for the extended forecast */
.forecast-card-container {
  display: flex;
  justify-content: space-between; /* Spread items evenly */
  padding: 10px;
  gap: 5px;
  max-height:fit-content; /* Set a maximum height */
  white-space: nowrap; /* Prevent wrapping of items */
}

/* Container for each period item */
.periodItem {
  flex: 0 0 auto; /* Allow items to shrink as needed */
  text-align: center;
  box-sizing: border-box;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 5px; /* Add some bottom margin for spacing */
}

.shortForecastText{
  text-align: center;
    font-size: 9pt;
    min-height: 4.5em;
}
/* Styling for Detailed forecast */
.detailedForecastHeader {
  font-size: 11px;
  line-height: 1;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  filter: brightness(110%);
  margin: 20px auto; /* Center the container horizontally */
  max-width: 85%;
  border: 1px solid rgb(128, 128, 128);
  /* Border around the container */
  border-radius: 5px;
  /* Border radius */
  box-shadow: rgba(140, 6, 265, 0.2);
  text-align: left;
  /* Ensures the text is left-justified */
}

/* Apply color difference between each forecast time directly to the container, skipping the first child */
.detailedForecastHeader>div:not(:first-child):nth-child(even) {
  background-color: #eff8fd;
  padding: .5em !important;
}

.detailedForecastHeader>div:not(:first-child):nth-child(odd) {
  background-color: #dee2e6;
  padding: .5em !important;
}

.detailedForecastHeader h5 {
  font-size: 12px;
  /* Change font size */
  font-weight: bold;
  /* Change font weight */
  color: #333;
  /* Change text color */
}

/* Style for the first child */
.detailedForecastHeader>div:first-child {
  background-color: #b7b7b7;
  /* Background color for the first child */
  padding: .5em .5em .25em .5em !important;
  /* Compensate for top padding */

}

.detailedForecastHeaderStyle h5 {
  font-size: 16px;
  /* Change font size */
  font-weight: bold;
  /* Change font weight */
  color: #333;
  /* Change text color */
}

.footer {
  font-size: 11px;
  line-height: 1;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  filter: brightness(110%);
  margin: 20px auto; /* Center the container horizontally */
  max-width: 85%;
  border: 1px solid rgb(128, 128, 128);
  /* Border around the container */
  border-radius: 5px;
  /* Border radius */
  box-shadow: rgba(140, 6, 265, 0.2);
  text-align: left;
  /* Ensures the text is left-justified */
  padding: .5em .5em .25em .5em !important;
}


/***************************************ModeBar Styling************************************************/
/*Styling for mode bar at the bottom of the page,position fixed along with
 bottom places the mode bar at the bottom of the page 
This keeps the mode bar at the bottom*/
.modebar {
  background-color: #585859;
  overflow: hidden;
  position: fixed;
  text-align: center;
  bottom: 0;
  width: 100%;
  z-index: 100;
  cursor: pointer;
}

/* Style the links inside the bottom nav bar
   I can split the bottom of the mode bar by changing the width. 
      ex. 32% would give me 3 sections. 
*/
.modebar a {
  display: block;
  float: left;
    /* This allows for me to add 4 footer options */
  width: 25%;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 2px 2px;
  text-decoration: none;
  font-size: 20px;
}

/* Add background color to button when it is hovered */
.modebar a:hover {
  background-color: #ffffff;
  color: #acaeae;
}

/* Style the text in the bottom bar */
.modebar-text {
  display: block;
  font-size: medium;
}

/********************************* Modal Pop-up Box styling **********************************************/

/* Modal Content/Box */
.modal {
  padding: 56px 8px 0px 8px;
  display: block;
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  
}

.about-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  text-align: center; /* Center the image */
}

/* CSS for modal container */
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  background-color: rgba(255, 255, 255, 0.5); /* White with 50% opacity */
  text-align: center; /* Center the image */
}

/* Media query for smaller screens */
@media (max-width: 800px) {
  .modal-content  img {
    width: 100%;
    /* Set image width to 100% of container on smaller screens */
    margin-right: 0;
    /* Remove right margin on smaller screens */
    margin-bottom: 20px;
    /* Add margin below images */
  }
}

/* Styling for the About header*/
.modal-header {
  padding: 2px 16px;
  color: white;
  text-align: center;
}

.modal-title {
  font-size: 28px;
  padding: 2px;
  color: rgb(0, 0, 0);
  text-align: center;
}

.modal-footer {
  padding: 2px 16px;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  margin: auto;
  margin-bottom: 20px; /* Adjust the value as needed */
}
.expanded-image {
  width: 60%; /* Set the width to 100% to make the image expand to the full width of the modal */
  height: auto; /* Maintain aspect ratio */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  margin: auto;
  max-height:fit-content; /* Set a maximum height */
  white-space: nowrap; /* Prevent wrapping of items */
}

.close {
  /* Remove position: fixed; */
  background-color: #b7b7b7;
  color: #000000;
  border: 0;
  border-radius: 0px;
  box-shadow: rgba(140, 6, 265, 0.2);
  padding: 10px;
  font-size: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-body {
  padding: 2px 16px;
}

/* Update the close button styling */
.close:hover,
.close:focus {
  background-color: #ffffff;
  color: #acaeae;
  float: right;
  font-size: 28px;
  font-weight: bold;
}


/* Style the close button at the bottom of modal box*/
.modal-submit-btn {
  align-items: center;
  text-align: center;
  display: flex;
  background-color: #b7b7b7;
  color: #000000;
  border: 0;
  border-radius: 0px;
  box-shadow: rgba(140, 6, 265, 0.2);
  padding: 3px;
  font-size: 15px;
  justify-content: space-evenly;
  width: auto;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  filter: brightness(110%);
}


/******************************** Space Weather Page******************************************************/
.spaceWeatherHeader {
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/******************************** Videos Page******************************************************/
.videos {
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ccc;
  margin: 20px;
  max-width: 85%;
  margin: 0 auto;  /* Center the card horizontally */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .videos {
    max-width: 100%;
  }

  /* Adjusted font size for smaller screens */
}

/* change items to gray when disabled class is applied  */
.disabled {
  color: gray;
}

.hide {
  display: none;
}

/*Used to adjust the layout and styling based on the device screen size****************************************/
/* Styles for mobile devices */

/******************************** Sites Page******************************************************/
.sitesHeader {
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ccc;
  margin: 20px;
  max-width: 85%;
  margin: 0 auto;
  /* Center the card horizontally */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sites{ 
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px;
  cursor: pointer; /* Change cursor to pointer */
  border: 1px solid #ccc;
  margin: 20px;
  max-width: 85%;
  margin: 0 auto;
  /* Center the card horizontally */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sites > div {
  flex: 1;
  margin-right: 20px;
  /* Adjust the margin as needed */
}

header {
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px;
}

/* ImageAnimation.css */
.image-animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Adjust as needed */

  /* Optional: Add styling for the container, like background color or border */
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}

.image-animation-container img {
  width: 100%;
  /* Adjust as needed */
  height: auto;
  /* Maintain aspect ratio */
  opacity: 0;
  /* Initially invisible */

  /* Animation properties */
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  /* Keeps the final state after animation */

  /* Add additional animation properties if needed (e.g., delay) */
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/******************************** Modal forecast ******************************************************/
/*Styling for navigation options forecast*/
.ForecastOptions {
  margin: 20px auto; /* Center the container horizontally */
  max-width: 85%;
  border: 1px solid rgb(128, 128, 128); /* Border around the container */
  border-radius: 5px; /* Border radius */
  overflow: hidden; /* Clear floats */
  background-color: #b7b7b7;
  color: #02033a;
  font-size: 20px;
  font-weight: bold;
  justify-content: space-evenly;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  filter: brightness(110%);
}

.ModalForecastText {
  justify-content: center;
  line-height: 1;
  /* line spacing after each line*/
  color: rgb(0, 0, 0);
  cursor: pointer;
  /* Show pointer cursor on hover */
  margin: 10px;
  display: flex;
  text-align: center;
  border-radius: 0px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-color: #d4def1;
  filter: brightness(120%);
}

.ModalForecastText:active,
.ModalForecastText:hover {
  color: #acaeae;
}

.customModalContent {
  /* Your existing styles */
  justify-content: center;
  margin: 20px;
  overflow-y: auto;
  max-height: fit-content; /* Set a maximum height */
  white-space: nowrap; /* Prevent wrapping of items */
  justify-content: space-between; /* Spread items evenly */
  /* This allows for vertical scrolling for overflow content */
  max-height: 60vh; /* Set a maximum height for the modal content */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .customModalContent {
    width: auto; /* Adjust the width for smaller screens */
    height: auto; /* Adjust the height for smaller screens */
  }
}

/******************************** Space Weather****************************************************/

/*Card to go around the entire webpage*/
.spaceWeatherHeader {
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ccc;
  margin: 20px;
  max-width: 85%;
  margin: 0 auto;
  /* Center the card horizontally */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Aurora Styling */
.aurora {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to a new row */
  justify-content: space-between; /* Distribute items evenly along the main axis */
  padding: 20px;
  align-items: center; /* Center items vertically within each row */
  border: 1px solid #ccc;
  margin: 20px auto; /* Center the container horizontally */
  max-width: 85%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Aurora items per row */
.aurora > div {
  width: calc(50% - 20px); /* Two items per row with 20px gap */
  margin-bottom: 20px; /* Adjust the margin for spacing */
  text-align: center; /* Center items horizontally */
}

/* Style for text below each image */
.gif-item .infoList {
  margin-top: 10px; /* Add some space between the image and the text */
}

/* Aurora img size and spacing */
.aurora img {
  width: 200px;
  /* Set initial width for images */
  margin-right: 20px;
  /* Add margin between images */
  margin-bottom: 20px;
  /* Add margin below images */
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .aurora img {
    width: 100%;
    /* Set image width to 100% of container on smaller screens */
    margin-right: 0;
    /* Remove right margin on smaller screens */
    margin-bottom: 20px;
    /* Add margin below images */
  }
}

/* GIF container styles */
.gif-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px;
  justify-content: center;
  /* Center items horizontally */
}

/* GIF item styles */
.gif-item {
  flex: 0 0 calc(50% - 10px);
  /* Two items per row with 20px gap */
  justify-content: center;
  /* Center items horizontally */
  margin: 20px;
  max-width: 85%;
  margin: 0 auto;
  /* Center the card horizontally */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .gif-item {
    flex: 0 0 100%;
    /* Full width for items on smaller screens */
    max-width: 100%;
    /* Set item width to 100% of container */
  }
}

.spaceFooter {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  margin: 20px;
  max-width: 85%;
  margin: 0 auto;
  /* Center the card horizontally */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/*Add text style and color to headers for each section*/
.Extended-Forecast-container {
  margin: 20px;
  border: 1px solid rgb(128, 128, 128); /* Border around the container */
  border-radius: 5px; /* Border radius */
  overflow: hidden; /* Clear floats */
}

/*Container for Radar Data*/
.RadarContainer {
  margin: 20px;
 border: 1px solid rgb(128, 128, 128); /* Border around the container */
 border-radius: 5px; /* Border radius */
 overflow: hidden; /* Clear floats */
}

.radarHeader {
  display: flex;
  flex-direction: column;
  line-height: 0.3; /* line spacing after each line*/
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  filter: brightness(110%);
  text-align: left;
  background-color: #b7b7b7;
}

.radarHeader h4 {
  margin: 0 auto;
  max-width: 85%;
  font-size: 20px;
  /* Change font size */
  font-weight: bold;
  /* Change font weight */
  color: #333;
  /* Change text color */
}

.radar {
  width: 100%; /* Set initial width to 100% */
  max-width: fit-content; /* Set maximum width to fit the content */
  margin: 0 auto; /* Center the card horizontally */
  cursor: pointer; 
}

.radar img {
  width: 100%; /* Set initial width to 100% */
  max-width: fit-content; /* Set maximum width to fit the content */
  height: auto; /* Maintain aspect ratio */
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .radar {
    max-width: 90%; /* Adjust maximum width for smaller screens */
  }
  
  .radar img {
    max-width: 100%; /* Adjust maximum width for smaller screens */
  }
}

.custom-input {
  width: 100%; /* Make the input field full-width */
  /* Add other custom input styles */
}

button {
  /* Add your custom styles for the button */
  margin-top: 10px; /* Add some spacing between the input field and button */
  /* Add other button styles */
}

button {
  /* Add your custom styles for the button */
  background-color: #b7b7b7;
  color: #000000;
  border: 0;
  border-radius: 0px;
  box-shadow: rgba(140, 6, 265, 0.2);
  padding: 5px 10px; /* Adjust the padding to make the button smaller */
  font-size: 14px; /* Adjust the font size to make the text smaller */
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  filter: brightness(110%);
}

/******************************** Gif style Page******************************************************/
.GifContainer {
  display: flex; /* Add flexbox display */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  padding: 20px;
  border: 1px solid #ccc;
  margin: 20px auto; /* Center the container horizontally */
  max-width: 85%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.GifContainer > div {
  flex: 1;
  width: calc(50% - 20px); /* Two items per row with 20px gap */
  margin-bottom: 20px; /* Adjust the margin for spacing */
  text-align: center; /* Center items horizontally */
}

@media screen and (max-width: 800px) { 
   .GifContainer{
    width: 100%;
   }
}

.gifHeader {
  align-items: center;
  text-align: center;
  justify-content: center;
}

/* Styling for the placeholder text */
.custom-input::placeholder {
  color: #aaa;
}

/* Styling for the custom speed of GIF*/
.customSpeed {
  color: #135897;
  margin: 5%;
  /* Add a transition for smooth animation */
  box-shadow: 0 2px 4px rgba(80, 87, 88, 0.1);
}

.generated-gif {
  max-width: 100%;
  max-height: 100%;
}

/* Styling for expanding GIF*/
.expandGIFtext {
  color: #135897;
  /* Add a transition for smooth animation */
  box-shadow: 0 2px 4px rgba(80, 87, 88, 0.1);
  cursor: pointer;
}

/* Styling for Choose files*/
.ChooseFiles {
  color: #135897;
 margin-top: 5%;
  /* Add a transition for smooth animation */
  box-shadow: 0 2px 4px rgba(80, 87, 88, 0.1);
  cursor: pointer;
}
.file-input-label {
  display: inline-block;
  position: relative;
  padding: 10px 20px;
  background-color: #b7b7b7;
  color: #000000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.file-input-label::after {
  content: '\25BA'; /* Unicode character for a right-pointing triangle */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

/* Optionally, you can hide the input element */
#file-input {
  display: none;
}

/* Define CSS rules for your component */

/* Style for the container of image groups */
.image-group-container {
  display: flex;
  flex-wrap: wrap; /* Allow images to wrap to the next line if there's not enough space */
  justify-content: space-between; /* Distribute images evenly across the container */
}

/* Style for each group of images */
.image-group {
  margin-bottom: 20px; /* Add some margin at the bottom of each group */
}

/* Style for each individual image */
.gif-item {
  margin-right: 10px; /* Add some space between each image */
}

.gif-item img {
  width: 250px; /* Set the width of each image */
  height: auto; /* Maintain aspect ratio */
  cursor: pointer; /* Change cursor to pointer when hovering over the image */
}

/* Style for the information list */
.infoList {
  cursor: pointer; /* Change cursor to pointer when hovering over the list */
  color: blue; /* Set text color to blue */
  font-weight: bold; /* Make the text bold */
  margin-top: 5px; /* Add some space at the top */
}

.top-button {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.top-button:hover {
  background-color: #555;
}

.top-button.show {
  display: block;
}

.top-button.hide {
  display: none;
}

.customModalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%; /* Adjust max-width as needed */
  width: auto;
  background: white;
  border: 1px solid #ccc;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.modalBody {
  margin-bottom: 20px;
}

.modalFooter {
  text-align: right;
}

.modalFooter button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modalFooter button:hover {
  background-color: #0056b3;
}
